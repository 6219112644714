module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Noto Sans","file":"https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap"},{"name":"Baloo 2","file":"https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap"},{"name":"Hind","file":"https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"अखिल भारतीय श्री ब्रजस्थ मैथिल ब्राह्मण महासम्मेलन आगरा, शाखा मथुरा","short_name":"अखिल भारतीय श्री ब्रजस्थ मैथिल ब्राह्मण महासम्मेलन आगरा, शाखा मथुरा","start_url":"https://brajhasthmaithilbrahmansabha.com","background_color":"#ffffff","theme_color":"#663399","display":"standalone","icon":"apple-touch-icon.png","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7Y1Y442WJ5"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
